.device-maker-img{
    background-image: url("../../images/smart-device-maker.jpg");
}

.network-operator-img{
    background-image: url("../../images/network-operator.jpg");
}

.system-integrator-img{
    background-image: url("../../images/system-integrator.jpg");
}