.whiteTextColor {
  color: #fff !important;
}
.navTitle {
  flex-grow: 1;
}
body {
  overflow-x: hidden !important;
}
.homepage {
  max-width: calc(100%+0px);
  margin: 0px;
}
.viewWidthHeight {
  width: 100%;
  height: 100vh;
}
.container-title {
  color: #666666;
  font-size: 28px;
  line-height: 42px;
  margin: 0 0 22px;
  font-weight: 600;
}

.container-heading1 {
  color: #666666;
  font-size: 20px;
  margin: 28px 0 10px;
  font-weight: 600;
}

.container-heading2 {
  color: #666666;
  font-size: 18px;
  line-height: 32px;
  margin: 0 0 10px;
  font-weight: 600;
}
.container-heading2--mod {
  margin-top: 12px;
}
.partnerbg{
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ),
  url("../../images/partnersBanner.jpg") !important;
}
.container-para {
  font-size: 17px;
  line-height: 26px;
  color: #737373;
  margin: 0 0 8px;
}
.custom-container {
  text-align: center;
}
.rowReverseAbout--mod{
  flex-direction: row-reverse;
  }

.borderedCards {
  background-color: #fff;
  /* box-shadow: 0.8px 0.8px 6px rgb(0 0 0 / 17%); */
}
.borderedCards--mod {
  background-color: #ededed !important;
}
.communitycards {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.image1 {
  background-image: url("../../images/gbrHome/advisor.webp");
}
.image2 {
  background-image: url("../../images/gbrHome/investor.webp");
}
.image3 {
  background-image: url("../../images/gbrHome/machinery.webp");
}
.image4 {
  background-image: url("../../images/gbrHome/exporter.webp");
}
.image5 {
  background-image: url("../../images/gbrHome/logisticproviders.webp");
}
.image6 {
  background-image: url("../../images/gbrHome/govenmentOrganization.webp");
}
.insurance-company-bg{
  background-image: url("../../images/standard-quality-control-collage-min.jpg");

}

.ari-bg{
  background-image: url("../../images/cropped-photo-female-botanist-disposable-latex-gloves-examining-basil-seedlings-with-magnifier-min.jpg");

}
/* .imageGovernmentOrganization{
  background-image: url("../../images/loan-business-finance-businessman-explain-business-report-from-data-analysis-bank-marketing.jpg");
} */
.image7 {
  background-image: url("../../images/gbrHome/honeybee.webp");
}
.image8 {
  background-image: url("../../images/gbrHome/processingMachine.webp");
}
.image9 {
  background-image: url("../../images/gbrHome/storageMachine.webp");
}
.image55 {
  background-image: url("../../images/gbrHome/producers.webp");
}
.technology-provider-image{
  background-image: url("../../images/technology-provider.jpg");

}
.image10 {
  background-image: url("../../images/gbrHome/fillilingMachine.webp");
}
.image11 {
  background-image: url("../../images/gbrPN/PNImg1.webp");
}
.image12 {
  background-image: url("../../images/gbrPN/PNImg2.webp");
}
.image13 {
  background-image: url("../../images/gbrPN/PNImg3.webp");
}
.image14 {
  background-image: url("../../images/gbrPN/PNImg4.webp");
}
.image15 {
  background-image: url("../../images/gbrPN/PNImg5.webp");
}
.agri-transporter-role{
  background-image: url("../../images/large-modern-semi-truck-with-trailer-highway-min.jpg");
}
.agri-retailer-role{
  background-image: url("../../images/cardboard-boxes-conveyor-belt-warehouse-min.jpg");
}

.agri-storage-entreprenuer-role{
  background-image: url("../../images/agricultural-silo-foreground-sunflower.jpg");
}
.image34 {
  background-image: url("../../images/technologist-white-protective-uniform-controlling-industrial-process-using-tablet-computer.jpg");
}
.image16 {
  background-image: url("../../images/gbrApp/image5.webp");
}
.image17 {
  background-image: url("../../images/gbrCBN/CBNImg2.webp");
}
.image18 {
  background-image: url("../../images/Agri-Business Infographic mobile 29-02-2024 C (1).gif");
}
.image19 {
  background-image: url("../../images/gbrCBN/CBNImg4.webp");
}
.image20 {
  background-image: url("../../images/gbrCBN/CBNImg5.webp");
}
.image21 {
  background-image: url("../../images/gbrCBN/CBNImg6.webp");
}
.image22 {
  background-image: url("../../images/gbrCBN/CBNImg7.webp");
}
.image23 {
  background-image: url("../../images/gbrCBN/CBNImg8.webp");
}
.image24 {
  background-image: url("../../images/gbrCBN/CBNImg9.webp");
}
.image25 {
  background-image: url("../../images/gbrCBN/CBNImg10.webp");
}
.image26 {
  background-image: url("../../images/gbrCBN/CBNImg11.webp");
}
.image27 {
  background-image: url("../../images/gbrCBN/CBNImg12.webp");
}

.image28 {
  background-image: url("../../images/Agrimachinery.jpg");
}
.image29 {
  background-image: url("../../images/Processingmachinery.jpg");
}
.image33 {
  background-image: url("../../images/contact-manufacturing-community.jpg");
}

.content-wrapper {
  padding: 40px 30px;
  align-self: center;
}
.about-section .container-title {
  margin-bottom: 30px !important;
  font-size: 26px;
}

.section-icon {
  filter: brightness(0) saturate(100%) invert(76%) sepia(27%) saturate(1031%)
    hue-rotate(95deg) brightness(91%) contrast(80%);
  border: 3px solid #3ecf8e;
  /* background-color: #808080; */
  /* background-color: #3ECF8E; */
  border-radius: 20px;
  padding: 20px;
  height: auto !important;
  width: auto !important;
}
.section-icon:hover {
  cursor: initial !important;
}
.section-inner-border {
  margin-bottom: 0;
}

button .MuiIconButton-label svg path {
  fill: rgb(255, 255, 255) !important;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 90% !important;
    margin: 0 auto;
  }
}
.subSectionWrapper {
  padding-top: 70px !important;
  padding-bottom: 70px;
}
.main-wrapper--mod {
  padding-bottom: 60px;
}

.main-wrapper {
  background-color: #ededed;
}
.MuiButton-root {
  text-transform: none !important;
}

.pn-para {
  font-size: 20px;
  line-height: 28px;
}

.subNetwork {
  padding: 20px 0 60px !important;
}
.cbnbg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/gbrCBN/CBNBanner.webp");
}
.abnbg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/gbrPN/agriculture-iot-with-rice-field-background-min.jpg") !important;
}
.pnbg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/forMSMEBanner.webp");
}
.agriEntrepreneur-bg{
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6)
  ),
  url("../../images/business-man-handshake-with-global-network.jpg");
}
.MSMEBenfitJourneyBg {
  padding: 320px 0 300px !important;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/asphalt-road-with-light-bulb-painted-min.jpg");
}
.foodprocessorMachinery {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/machinery.jpg");
}
.MSMEBanner {
  /* padding: 300px 0 300px ; */
  background-position: initial !important;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/test3.jpg");
}

.network-operator-bg{
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/network-operator-bg.jpg");
}
.solution-provider-bg{
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/solution-providers-bg.jpg");
}

.system-integrator-bg{
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/system-integrator-bg.jpg");
}

.device-maker-bg{
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/device-maker-bg.jpg");
}

.AgentBanner {
  /* padding: 300px 0 300px ; */
  background-position: initial !important;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/young-indian-handsome-agronomist-talking-smartphone-field.jpg");
}
.benefitList {
  padding-left: 20px;
  list-style-type: circle !important;
}
.benefitList li {
  font-size: 16px;
  line-height: 22px;
  color: #737373;
  margin-bottom: 8px;
}
.network-wrapper {
  padding: 20px 0 30px !important;
}
.IAA-mangement-wrapper {
  padding: 50px 0 0px;
}
.fieldSupportService1{
  background-image: url("../../images/FeedManagementServices/image1.jpg");
}
.fieldSupportService2{
  background-image: url("../../images/FeedManagementServices/image2.jpg");
}
.fieldSupportService3{
  background-image: url("../../images/FeedManagementServices/image3.jpg");
}
.fieldSupportService4{
  background-image: url("../../images/FeedManagementServices/businessman-s-hand-is-holding-umbrella-wooden-family.jpg");
}
.fieldSupportService5{
  background-image: url("../../images/FeedManagementServices/loan.jpg");
}
.fieldSupportService6{
  background-image: url("../../images/FeedManagementServices/request.jpg");
}
.fieldSupportService7{
  background-image: url("../../images/FeedManagementServices/care-service.jpg");
}
.imageStrCommittee{
  background-image: url("../../images/group-diverse-people-having-business-meeting-min.jpg");
}


.ProcessingMachineImg {
  background-image: url("../../images/gbrHome/quality-control-workers-examining-pills-lab-min.jpg");
}
.sheepImg {
  background-image: url("../../images/gbrHome/close-up-sheep-stable-looking-camera-min.jpg");
}
.ngo-img{
  background-image: url("../../images/agriproducer-img/NGO/hand-holding-young-plant-green-background-with-sunshine-eco-concept-earth-day-min.jpg");
}
.fpo-img{
  background-image: url("../../images/agriproducer-img/FPO/agricultural-workers-growing.jpg");
}
.cs-img{
  background-image: url("../../images/agriproducer-img/CS/aerial-view-group-traditional-farmer-planting-rice-field-min.jpg");
}
.shg-img{
  background-image: url("../../images/agriproducer-img/SHG/countryside-people-working-field-min.jpg");
}
@media screen and (max-width: 1280px) {
  .MuiContainer-maxWidthLg {
    width: 92% !important;
  }
}
@media screen and (max-width: 769px) {
  .aboutSubSection {
    margin-top: 20px;
    padding: 20px 0;
  }
  .communitycards {
    height: 250px;
    width: 100%;
  }

  .content-wrapper {
    padding: 25px 25px;
  }
  .about-section .container-title {
    margin-bottom: 20px !important;
    font-size: 22px;
    line-height: 32px;
  }

  .MSMEBenfitJourneyBg {
    padding: 150px 0 100px !important;
  }
  .container-title {
    font-size: 26px;
    line-height: 40px;
    margin: 0 0 20px;
  }

  .subSectionWrapper {
    padding-top: 50px !important;
    padding-bottom: 50px;
  }

  .abn-container {
    padding: 60px 0 0px 0;
  }
  .main-wrapper--mod {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 769px) {
  .section3BodyContainer {
    display: flex !important;
    flex-direction: row !important;
    overflow-x: auto;
    flex-wrap: nowrap !important;
  }
  .section3Body {
    min-width: 220px !important;
    margin: 10px 10px 10px 5px !important;
  }
  .partner-journey-container--mod .section3BodyContainer,
  .agriminor-journey-container .section3BodyContainer,
  .IAA-mangement-wrapper .section3BodyContainer {
    justify-content: left !important;
  }
  .MSMEBanner{
    background-position: right !important;
  }
}
@media only screen and (min-width: 992px) {
  .section3Body {
    width: 25% !important;
    justify-content: center;
  }
  .section3Body:hover {
    /* transition: width 2s, height 2s, transform 1s; */
    transform: 0.3s ease-in-out;
    transform: scale(1.05);
  }
  
}

@media only screen and (min-width: 769px) and (max-width: 993px) {
  .section3Body {
    width: 44% !important;
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .container-banner,
  .AgriMinorContainer,
  .businessPartnerContainer,
  .about-us-banner,
  .update-banner-container,
  .serviceContainer,
  .network-banner,
  .pricing-banner {
    background-attachment: initial !important;
  }
}

.radio-btn{
  width: auto !important;
}

.cursor-initial:hover{
  cursor: initial !important;
}
.ExploreBannerContainer{
  background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../images/solution-marketplace.jpg") !important;
}
.fieldSupportService8{
  background-image: url("../../images/FeedManagementServices/operator-field-service.jpg");
}
.fieldSupportService9{
  background-image: url("../../images/FeedManagementServices/QA-field-service.jpg");
}
.fieldSupportService10{
  background-image: url("../../images/FeedManagementServices/agent-fied-service.jpg");
}
.agri-storage-entreprenur{
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url("../../images/metal-elevator-grain-silo-agriculture-zone-grain-warehouse.jpg");
}
.farmUpdateBanner {
  background-position: bottom;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .45), rgba(0, 0, 0, .45)), url("../../images/beekeeping.jpg");
}
.abn-container{
  padding: 30px 0 0px 0;
}
.abn-container .MuiButton-root{
  text-transform: none;
}
.networkImg{
  width: 50% !important;
  height: 50% !important;
}
.mob-vis{
  display: none !important;
  /* width: 100%;
  height: 100%; */
}
.mob-hide{
  display: inline-block !important;
  /* width: 100%;
  height: 100%; */
}

.abn-container img{
  width: 100%;
  height: auto;
}
.abn-container img:hover{
  cursor: initial !important;
}
.abn-container a{
  text-decoration: none;
  line-height: 23px;
  color: white;
}

.abn-container button{
  margin-top: 25px;
}
.mob-vis1{
  margin: 30px auto 0;
  width: 80%;
  height: auto;
  display: block;
}
.mob-vis2{
  margin: 30px auto 0;
  width: 60%;
  height: auto;
  display: block;
}

.mob-vis3{
  margin: 30px auto 0;
  width: 50%;
  height: auto;
  display: block;
}
@media screen and (min-width:769px){
  .abn-container .container-para{
      padding: 0 150px;
  }
.mob-vis{
  margin: 30px auto 0;
  /* width: 60%; */
  display: inline-block !important;
}
.mob-hide{
  display: none !important;
}
}

@media screen and (max-width:769px){
  .abn-container{
      padding: 20px 0 0px 0;
  }
  .mob-vis1,.mob-vis2, .mob-vis3{
      width: 100%;
      height: auto;
  }
}

@media screen and (max-width:769px){
  .about-main-wrapper{
      margin: 30px 0 70px;
  }
  .aboutSubSection img{
      border-radius: 0;
  }
  .about-us-banner{
      padding: 150px 0 100px;
  }
  .about-us-banner h2{
      font-size: 24px;
      line-height: 40px;
  }
  .rowReverseAbout--mod,  .rowReverseAbout{
      flex-direction: column-reverse;
  }
  .rowReverseAbout--mod img,  .rowReverseAbout img{
      margin-bottom: 30px;
  }
}

.for-farmer-section2 img{
  width: 100%;
  height: auto;
  
  }
  .for-farmer-section2 a{
      text-decoration: none !important;
  }
  
  
  
  @media screen and (min-width:992px){
      .image-container{
          overflow: hidden;
          width: 100%;
          height: 237px;
          overflow: hidden;
      }
      
      .image-container img {
          height: 160px;
          width: 100%;
          transition: 0.5s all ease-in-out;
          object-fit: cover;
      }
      
      .image-container:hover img {
          transform: scale(1.2);
      }
  }

  .benefitsCardContainer{
      border-radius: 4px;
      padding: 30px;
  }
  .benefitsCardContainer:hover{
    box-shadow:0.7143rem 0.7143rem 2.1429rem 0 rgba(0,0,0,0.04);
  }
  .benefitsCardContainer img{
      height: 60px;
      width: 60px;
  }
  .benefitsCardContainer h3{
      font-size: 16px;
      line-height: 26px;
      color: #121212bf;
      font-weight: 700;
  }
  
  .benefitsCardContainer p{
      font-size: 16px;
      line-height: 24px;
      color: #323131bf;
  }
  
  
  @media screen and (max-width:769px){
      .benefitsCardContainer{
          box-shadow: 0.7143rem 0.7143rem 2.1429rem 0 rgba(0,0,0,0.04);
          padding: 20px;
      }
  }
  

  .agriretailer-bg{
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("../../images/asian-woman-farmer-using-digital-tablet-vegetable-garden-greenhouse-business-agriculture-technology-concept-quality-smart-farmer.jpg");
  }