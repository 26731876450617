.update-banner-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  align-items: center;
  padding: 260px 0 180px;
  background-attachment: fixed;
}
/* .BannerHeading{
  font-size: 40px !important;
} */
.update-banner-container h1{
  font-size: 34px;
  margin: 0 0 20px;
  line-height: 46px;
  font-weight: 600;
}
.update-banner-container .bodycopy{
  font-size: 18px;
  margin: 0 0 26px;
  line-height: 30px;
  font-weight: 400;
}
.buttonIcon{
  width: 16px;
  height: 16px;
}
.update-banner-container button:hover{
  background-color: transparent;
}
@media screen and (max-width: 769px) {
  .update-banner-container {
    padding: 150px 0 150px;
  }
  .update-banner-container h1{
    font-size: 24px;
    margin: 0 0 20px;
    line-height: 40px;
}
.update-banner-container .bodycopy, 
.update-banner-container p{
  font-size: 14px;
    margin: 0 0 20px;
    line-height: 24px;
}
}


.changing-text {
  display: inline-block;
  transition: transform 0.5s ease-in-out; 
}