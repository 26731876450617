.forManufactures{
    background-size: 100% 100%, /* Top gradient size */
                 cover, /* Image size */
                 100% 100%; /* Bottom gradient size */

    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("../../images/man-apron-checking-digital-tablet-greenhouse-min.jpg"),linear-gradient(rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.69));
}

.for-manufacturers-wrapper{
    margin:22px 0 70px 0;
}
.Iaa-Cards-mod{
    box-shadow: none;
    border: none;
}
.Iaa-Cards-mod img{
    width: 100px !important;
    height: 100px !important;
}

.Iaa-manufacturer-tabs .container-para + div > div:first-child{
    width: 40%;
}
.card-activated{
    border: 1px solid #2A4355;
    cursor: initial !important;
}
.card-activated:checked{
    border: none !important;
    cursor: initial !important;
}