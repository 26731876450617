.sectionGrey{
    background-color: #f6f6f6;
}
.sectionWhite{
    background-color: white;
}
.lists li{
    list-style: none;
}
.whyAgristack{
    padding-bottom: 30px;
}

.agristackContainer{
    background-position: center;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.486), rgba(0, 0, 0, 0.432)),url("../../images/Explore-apps1.jpg") !important;
}
.explore-app-background{
    background-position: center;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.486), rgba(0, 0, 0, 0.432)),url("../../images/futuristic-api-integration-concept-with-neon-lights.jpg") !important;
}
.explore-marketplace-bg{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url("../../images/marketplace-bg4.jpg") !important;

}
.technology-section .contentContainer{
    padding: 82px 0 0 !important;
}

.lists li{
    margin-bottom: 0 !important;
}
/* .technology-section{
    margin-bottom: 40px;
} */
.technology-section .contentContainer ul{
    padding: 0;
}
.agristackContainer img{
    width: auto !important;
    height: auto !important;
}
.technology-subsection{
    margin-bottom: 20px !important;
}

@media screen and (max-width:769px){
    .technology-section .contentContainer{
        padding: 60px 0px 0 !important;
    }
}


@media screen and (min-width:1025px) {
    .stack-data-container {
        flex: 1 1 calc(20% - 10px); 
        margin: 10px;
        box-sizing: border-box; 
        text-align: center;
      }
      
}
