.agribusiness-stack{
    padding: 20%  0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.deployment-container div{
    color: #ffffff;
    border: 1px solid #ffffff;
    padding: 10px 30px;
    font-size: 24px;
    width: 80%;

}

@media screen and (min-width:769px){
.deployment-container :first-child{
    width: 30%;
}
.deployment-container :nth-child(2){
    width: 50%;
}
.deployment-container :last-child{
    width: 70%;
}
}