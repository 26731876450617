.agribusiness-banner-bg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../images/environment-landscape-green-agricultural-field-countryside-scene-min.jpg") !important;
}

.agribusiness-img {
  background-image: url("../../images/AAMC.jpg");
}

.agro-dealer-img {
  background-image: url("../../images/businessman-shaking-hands-each-oth-analysis-digital-stock-market-financial-background.jpg");
}

.agri-foodmanufacturer-img {
  background-image: url("../../images/young-smiling-manager-sterile-uniform-holding-tablet-looking-camera-while-standing-food-factory-min.jpg");
}
.cbo-img {
  background-image: url("../../images/top-view-diverse-people-hands-holding-together-circle-hands-stack-min.jpg");
}
.msme-img{
  background-image: url("../../images/gbrPN/PNBanner.webp");

}