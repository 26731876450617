.club-container-wrapper{
    padding: 90px 0 40px;
}

.club-container .container-heading2{
    font-size: 16px;
    cursor: pointer;
}
.club-container .card{
    padding: 0;
}
.club-container .card-body{
    padding: 16px 25px;
}
.cardcontainer{
  min-width: 100px;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}
.containerbody{
    width: 30% !important;
    margin: 1%;
    display: inline-flex;
    float: left;
};

.card {
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    text-align: center;
}

.signdiv{
    width: 100%;
}

.signbutton{
    width: 45% !important;
    height: 40px;
}

 .card-content{
    color: #808080;
    font-size: 14px;
}
.club-container .card-body button:hover{
    border: none;
}

.club-container .card-header{
    background-color:#3ECF8E !important;
    border-bottom: none;
}
.club-container .container-heading1{
    color: white !important;
    font-size: 16px;
}
.club-container h6 p{
    font-size: 13px;
    line-height: 22px;
    color: #808080;
}
.club-container h6 a{
    text-decoration: none;
    color: #808080 !important;

}
.club-heading{
    width: 80%;
    text-align: left;
}
@media screen and (max-width:992px){
    .containerbody{
        width: 40% !important;
    };
}



.club-container .card{
    margin: 10px 0 !important;
}

@media screen and (max-width:769px){
    .containerbody{
        width: 100% !important;
    }
    .navbarRight-mod,.ForwardRef-root-2{
        width: 100% !important;
    }
    .club-container-wrapper{
        padding: 80px 0 30px !important;
    }
}
.card {
    margin: 10px 0;
}
.club-searchbar .MuiIconButton-label svg path{
    fill: #808080 !important;
}

@media screen and (min-width:769px){
    .navbarRight-mod{
        width: 100% !important;
    }
}

.navbarRight-mod .ForwardRef-root-2{
    width: 100% !important;
}