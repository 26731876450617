.section2Container{
    padding: 70px 0;
    text-align: center;
}
/* .serviceContainer .gridSection{
    width: 900px;
} */

.serviceContainer .MuiGrid-spacing-xs-2 > .MuiGrid-item{
    padding: 18px 8px;
}

.serviceContainer{
    background-attachment: fixed;
    max-width: calc(100%+0px);
    margin: 0px;
    /* min-height: 100vh; */
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url("../../images/human-resource-management-hr-skyscrapers-background-min.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    padding: 244px 0 200px;
 }

 .service-section .section-inner-border{
    margin-bottom: 0;
 }
.service-section .contentContainer{
    padding:82px 0 0 !important;

}
.banner-icon{
    height: auto !important;
    width: auto !important;
}
.gridSection h4{
    font-size: 17px;
    line-height: 26px;
}
@media screen and (max-width:769px){
    .section2Container {
        padding: 50px 0;
    }

    .gridSection h4{
        font-size: 14px;
        line-height: 22px;
    }
    .service-section .contentContainer{
        padding:60px 0 0 !important;
    }
    .serviceContainer{
        padding: 120px 0 40px;
    }
}

.serviceSubSection h3 {color: #666666;
    font-size: 18px;
    margin: 28px 0 10px;
}


.serviceSubSection p{
    font-size: 17px;
    line-height: 26px;
    color: #808080;
    margin: 0 0 0px;
}