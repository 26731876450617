.our-solution {
    display: flex;
    flex-direction: column;
  }
  
  .services-card {
    border-radius: 6px;
    border: 1px solid gray;
    padding: 10px;
    justify-content: space-between;
    height: 100%;
  }

  .services-card button{
    padding:6px;
    border: 2px solid #666666;
    color: #666666;
    font-size: 12px;
    font-weight: bold;
    border-radius: 10px;
    text-decoration: none;  }

  
    .services-card button a{
        color: #666666;
    }

    .challenge-container img{
      width:100% !important;
      height: 100% !important;
    }
    
    .challenge-container img:hover{
        cursor: initial !important;
    }
    
    .challangeCards{
      /* border:0.8px solid #3ecf8e; */
      padding: 8px;
    }
    .challangeCards h6{
      font-size: 20px;
      line-height: 29px;
      font-weight: 600;
      color: #666666;
    }
    
    .challangeCards p{
      font-size: 16px;
      line-height: 26px;
      color: #707070;
    }
    .challangeCards img{
      width: 45px !important;
      height: auto !important;
    }
    
    
    @media screen and (max-width:768px){
    .challangeCards {
      box-shadow: 0px 3px 12px #0000000d;
      padding: 24px;
      background-color: #fff;
    }
    
    .challangesRow{
      overflow-x: auto;
      flex-wrap: nowrap;
    }
    .challangesRow::-webkit-scrollbar {
      display: none;
    }
    
    }